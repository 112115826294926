import React, { useState } from 'react';

import { MdCheck, MdClose } from 'react-icons/md';
import Select, { SingleValue } from 'react-select';
import type {} from 'react-select/base';
import { Link } from 'react-router-dom';

import { appStoreOptions, getPrice, locationOptions } from '../../util/Prices';
import { FreeTrialDays, TopicCount } from '../../util/Values';

import './PlansAndPricing.css';

function PlansAndPricing() {
  const [location, setLocation] = useState({
    value: 'usa',
    label: 'United States',
  });

  const [appStore, setAppStore] = useState({
    value: 'apple',
    label: 'Apple App Store',
  });

  const onLocationChange = (
    newValue: SingleValue<{ value: string; label: string }>,
  ): void => {
    setLocation(newValue ?? locationOptions[0]);
  };

  const onAppStoreChange = (
    newValue: SingleValue<{ value: string; label: string }>,
  ): void => {
    setAppStore(newValue ?? appStoreOptions[0]);
  };

  const price = getPrice(location.value, appStore.value);
  const priceText = price ? `${price}/month` : 'Price not found';

  return (
    <div className="plans-and-pricing">
      <h4>Plans and Pricing</h4>
      <p>
        To support the growth of our <strong>PhraseForge Slovak</strong> app,
        we&apos;re currently offering a special reduced subscription rate for a
        limited time. Please note that prices for new subscribers will increase
        in the future. By subscribing today, you can lock in the current rate
        for as long as your subscription remains active. We appreciate your
        support in our mission to provide a quality Slovak learning app!
      </p>
      <div className="location-selector-container">
        <div className="location-selector-area-left">
          <div className="location-selector-label-text">Location:</div>
        </div>
        <div className="location-selector-area-right">
          <Select
            className="location-selector"
            defaultValue={location}
            onChange={onLocationChange}
            options={locationOptions}
          />
        </div>
      </div>
      <div className="location-selector-container">
        <div className="location-selector-area-left">
          <div className="location-selector-label-text">App store:</div>
        </div>
        <div className="location-selector-area-right">
          <Select
            className="location-selector"
            defaultValue={appStore}
            onChange={onAppStoreChange}
            options={appStoreOptions}
          />
        </div>
      </div>
      <table className="plan-table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Free Version</th>
            <th>Standard Subscription</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <div className="price">{priceText}</div>
              <div className="trial">{FreeTrialDays}-day free trial</div>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/language-coverage">
                Full course with {TopicCount} topics
              </Link>
            </td>
            <td className="included">
              <p className="note-balance">I</p>
              <MdCheck size={30} />
              <br />
              <p className="note">(recommended lessons only)</p>
            </td>
            <td className="included">
              <MdCheck size={30} />
            </td>
          </tr>
          <tr>
            <td>Recommended Lessons</td>
            <td className="restricted">1 per day</td>
            <td className="included">Unlimited</td>
          </tr>
          <tr>
            <td>Help Topics</td>
            <td className="not-included">
              <MdClose size={30} />
            </td>
            <td className="included">
              <MdCheck size={30} />
            </td>
          </tr>
          {/* Select Mode */}
          <tr>
            <td>Select Mode</td>
            <td className="not-included">
              <MdClose size={30} />
            </td>
            <td className="included">
              <MdCheck size={30} />
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/mastery-ratings">Mastery Ratings</Link>
            </td>
            <td className="not-included">
              <MdClose size={30} />
            </td>
            <td className="included">
              <MdCheck size={30} />
            </td>
          </tr>
          <tr>
            <td>General Revision</td>
            <td className="not-included">
              <MdClose size={30} />
            </td>
            <td className="included">
              <MdCheck size={30} />
            </td>
          </tr>
          <tr>
            <td>Vocab Drills</td>
            <td className="not-included">
              <MdClose size={30} />
            </td>
            <td className="included">
              <MdCheck size={30} />
            </td>
          </tr>
        </thead>
      </table>
      <h5>Subscription Benefits</h5>
      <p>
        Get our Standard Subscription to get the most out of PhraseForge Slovak.
        This subscription provides you with access to all of our features and
        benefits. See the table above for more details.
      </p>
      <p>
        Our Standard Subscription is available for a monthly fee with a{' '}
        {FreeTrialDays}-day free trial period. You can get the Standard
        Subscription by installing the PhraseForge Slovak app from Apple App
        Store or Google Play store. After completing the first lesson,
        you&apos;ll be prompted to login and then start the free trial.
      </p>
      <p>
        Without a subscription, you can still use the app, but you&apos;ll only
        be able to complete one lesson per 24 hours, and you won&apos;t be able
        to access the full range of features. We&apos;ll give you two extra free
        lessons when you start so that you can get going!
      </p>
      <p>
        We&apos;re planning to add more subscription options in the future, so
        stay tuned!
      </p>
    </div>
  );
}

export default PlansAndPricing;

import React from 'react';

import { Link } from 'react-router-dom';

import './TermsOfUse.css';

function TermsOfUse() {
  return (
    <div className="terms-of-use">
      <h3>Terms of Use</h3>
      <p>
        Last updated September 20, 2024
      </p>
      <h4>1. General</h4>
      <h5>1.1 Introduction </h5>
      <p>
        We are PhraseForge Languages Pty Ltd (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;), a private company
        registered in Australia at 30 Canterbury Road, Heathmont, Victoria 3135.
        Our ACN (Australian Company Number) is 680 626 608.
      </p>
      <h5>1.2 Scope</h5>
      <p>
        These terms and conditions (the &quot;Agreement&quot;) govern your use of the PhraseForge Slovak mobile application
        (the &quot;App&quot;), the PhraseForge website (the &quot;Website&quot;) and any other related products and
        services (collectively, the &quot;Services&quot;) that refer or link to these legal terms (the &quot;Terms&quot;).
        By accessing or using the Services, you agree to be bound by these Terms. If you disagree with
        any part of the Terms, then you may not access the Services.
      </p>
      <h5>1.3 Changes</h5>
      <p>
        We may update the Terms from time to time. We will notify you of any substantive changes by posting the new
        Terms on this page, and sending a notification within the App.
      </p>
      <h4>2. Services</h4>
      <h5>2.1 Description</h5>
      <p>
        The Services are designed to help you learn Slovak by providing instructions, exercises
        and feedback in the Slovak language. We may provide services for free with limited
        functionality (&quot;Free Services&quot;) or for a fee with additional functionality
        (&quot;Paid Services&quot;) as specified prior to purchase.
      </p>
      <h5>2.2 Changes</h5>
      <p>
        We may change the Services at any time, including by adding or removing features or functionalities.
        We may also stop providing the Services, or any part of them, at any time.
      </p>
      <h5>2.3 Access</h5>
      <p>
        You may access Free Services by downloading the App from the Apple App Store or Google Play Store.
        You may access Paid Services by purchasing a subscription through the App, or opting into a free trial.
        </p>
      <h5>2.4 Availability</h5>
      <p>
        PhraseForge Languages will make reasonable efforts to ensure that the Paid Services are available at all times.
        We do not guarantee that the Services will always be available, or that they will be free from errors
        or interruptions.
      </p>
      <h5>2.5 Support</h5>
      <p>
        If you have any questions or complaints about the Services, please <Link to="/contact-us">contact us</Link>.
      </p>
      <h4>3. Intellectual Property</h4>
      <p>
        We own and control all the copyright and other intellectual property rights in our Services and
        the material on our Services.
      </p>
      <p>
        The copyright and other intellectual property rights in the Services and the material on our Services are reserved.
      </p>
      <h4>4. License</h4>
      <h5>4.1 License</h5>
      <p>
        We grant you a limited, non-exclusive, non-transferable, revocable license to use the Services for
        your personal, non-commercial use.
      </p>
      <h5>4.2 Prohibited Activities</h5>
      <p>
        You must not use any information obtained from our Services to harass, abuse, or harm another person.
      </p>
      <p>
        You are prohibited from uploading or transmitting (or attempting to upload or transmit) viruses,
        Trojan horses, spyware, or any other material that disrupts, impairs, or interferes with any party&apos;s
        uninterrupted use and enjoyment of the Services. This includes any actions that modify, impair,
        disrupt, alter, or interfere with the use, features, functions, operation, or maintenance of the Services.
      </p>
      <p>
        You must not circumvent, disable, or otherwise interfere with security-related features of the Services.
        This includes features that prevent or restrict the use or copying of any content or enforce
        limitations on the use of the Services and/or the content contained therein.
      </p>
      <p>
        You must not engage in systematic retrieval of data or other content from the Services to create or compile,
        directly or indirectly, a collection, compilation, database, or directory without our
        written permission is strictly prohibited.
      </p>
      <p>
        You must not trick, defraud, or mislead us or other users, especially in any attempt
        to learn sensitive account information such as user passwords.
      </p>
      <p>
        You must not use the Services in a way that is inconsistent with applicable laws and regulations.
      </p>
      <p>
        You are prohibited from interfering with, disrupting, or creating an undue burden on the
        Services or the networks or services connected to the Services.
      </p>
      <p>
        You must not attempt to bypass any measures designed to prevent or restrict access
        to the Services, or any portion of the Services.
      </p>
      <p>
        Except as permitted by applicable law, you are prohibited from deciphering, decompiling, disassembling,
        or reverse engineering any of the software comprising or in any way making up a part of the Services.
      </p>
      <h4>5. Registration</h4>
      <h5>5.1 Account Security</h5>
      <p>
        You agree to maintain the confindentiality of any passwords used in registration
        with the services, and maintain the security of any other information used to access
        the Services, such as email addresses, third-party authentication services, or
        usernames.
      </p>
      <h5>5.2 Account Information</h5>
      <p>
        You agree to provide accurate and complete information when registering for the Services.
      </p>
      <h5>5.3 Username</h5>
      <p>
        We reserve the right to remove, reclaim, or change a username you select if we determine,
        in our sole discretion, that such username is inappropriate, obscene, attempting to impersonate
        another person, or otherwise objectionable.
      </p>
      <h5>5.4 Account Usage</h5>
      <p>
        You must not use the account to access the Services, unless you have the account owner&apos;s
        express permission to do so.
      </p>
      <h5>5.5 Notification</h5>
      <p>
        You must notify us immediately of any unauthorized use of your account, disclosure of any passwords,
        or any other breach of security.
      </p>
      <h5>5.6 Deletion</h5>
      <p>
        You may request deletion of your account, including all your data on our live production services,
        by selecting Settings &gt; Account Settngs &gt; Delete Account while logged into the account.
        You may also request deletion by <Link to="/contact-us">contacting us</Link>. Account deletion will
        disable your access to any Services associated with that account.
      </p>
      <h4>6. Purchases</h4>
      <h5>6.1 Payment</h5>
      <p>
        Payment is required to access most features of the Services. Payments will
        be processed through{" "}
        <Link to="https://play.google.com/about/play-terms/index.html">
          Google Play
        </Link>{" "}
        or the{" "}
        <Link to="https://www.apple.com/legal/internet-services/itunes/">
          Apple App Store
        </Link>{" "}
        from which You originally downloaded the application, using the services of{" "}
        <Link to="https://www.revenuecat.com/terms/">
          RevenueCat
        </Link>{" "}
        as an intermediary. you may access the applicable terms and conditions and
        privacy policies on their websites, as linked above.
      </p>
      <h5>6.2 Refunds</h5>
      <p>
        You may request a refund for your subscriptions through the Google Play Store
        or Apple App Store from which you originally downloaded the application
        according to their respective refund policies. We do not offer refunds through any
        other means or channels.
      </p>
      <h4>7. Subscriptions</h4>
      <h5>7.1 Billing and Renewal</h5>
      <p>
        All subscriptions will continue and automatically renew unless canceled. You
        consent to our charging your payment method on a recurring basis without
        requiring your prior approval for each recurring charge, until such time as
        you cancel the applicable order. The length of your billing cycle is monthly,
        or for a longer duration as selected by you through the options provided in
        the application.
      </p>
      <h5>7.2 Free Trial</h5>
      <p>
        We offer a free trial to new users who register with the Services. You
        will be automatically charged unless you notify us of the cancellation before
        the last 24 hours of the trial subscription.
      </p>
      <h5>7.3 Association with Account</h5>
      <h6>7.3.1 Initial Association</h6>
      <p>
        Each subscription will be bound (a) to the Google Play Store or Apple App Store account from which the
        subscription was purchased, and (b) to the user account that was used to purchase the subscription.
      </p>
      <h6>7.3.2 Account Transfer</h6>
      <p>
        You may transfer the subscription to another user account by
        selecting Settings &gt; Subscriptions &gt; Restore Purchase while logged into that user account,
        allowing the features of that subscription to be used while logged into that user account. A
        subscription cannot be transferred to a user account that is associated with a separate subscription.
        Any subscription transferred to another user account will no longer be usable under the original
        account from which the subscription was transferred. This subscription will still be
        bound to the Google Play Store or Apple App Store account from which the subscription was
        purchased.
      </p>
      <h5>7.4 Cancellation</h5>
      <p>
        You may cancel your subscription through the app store from which you originally downloaded the App
        by using their respective subscription management tools. You can find these tools by selecting
        Settings &gt; Subscriptions &gt; Manage Subscriptions while logged into the user account which the
        account is currently associated with. You may only cancel a subscription using the
        Google Play Store or Apple App Store account from which the subscription was purchased.
        You will continue to be able to access the features you are entitled to under the subscription
        until the end of the billing cycle.
      </p>
      <h4>8. User Generated Contributions</h4>
      <h5>8.1 User Content</h5>
      <p>
        The Services offer users to submit content in the form of responses to exercises
        and feedback for the application. We may provide you with the opportunity to
        submit, post or transmit content and materials to us or on the
        Services, including but not limited to text, writings, comments, audio, suggestions, or
        other material (collectively, &quot;Contributions&quot;).
        As such, any Contributions you transmit may be treated in accordance with
        our{" "}
        <Link to="/privacy-policy">
          Privacy Policy
        </Link>.
      </p>
      <h5>8.2 Rights</h5>
      <p>
        By submitting suggestions or other feedback regarding the Services, you
        agree that we can use and share such feedback for any purpose without
        attribution or compensation to you.
      </p>
      <h5>8.3 Limitations on Contributions</h5>
      <p>
        You may not post any Contributions that are illegal, offensive, or
        otherwise objectionable. You may not post any Contributions that infringe
        on the intellectual property rights of any third party.
      </p>
      <h4>9. Privacy Policy</h4>
      <p>
        We prioritize data privacy and security. Please review our {" "}
        <Link to="/privacy-policy">
          Privacy Policy
        </Link>. By using the
        Services, you agree to adhere to our Privacy Policy, which is integrated into these Legal Terms.
        The Services are hosted in the United States.  If you access the Services from a region with laws
        or requirements regarding personal data collection, use, or disclosure that differ from those in
        the United States, your continued use of the Services indicates your consent to transfer and
        process your data in the United States.
      </p>
      <h4>10. Limited Warranty and Disclaimer</h4>
      <p>
        We shall use commercially reasonable efforts consistent with prevailing industry standards
        to maintain the Services in a manner which minimizes errors and interruptions in the Services.
        However, the Services may be temporarily unavailable for scheduled maintenance or for
        unscheduled emergency maintenance, either by us or by third-party providers, or because
        of other causes beyond our reasonable control. We do not warrant that the Services will
        be uninterrupted or error-free; nor do we make any warranty as to the results that may be
        obtained from use of the Services.
      </p>
      <p>
        The Services are provided on an &quot;as is&quot; and &quot;as available&quot; basis. We hereby disclaim all
        warranties of any kind, express or implied, including, without limitation, the warranties
        of merchantability, fitness for a particular purpose, and non-infringement. We do not make
        any warranty that the Services will meet your requirements, or that the Services will be
        uninterrupted, timely, secure, or error-free, or that defects in the Services will be corrected.
      </p>
      <h4>11. Limitation of Liability</h4>
      <p>
        In no event will we be liable to you or any third party for any special, incidental, indirect,
        punitive, or consequential damages whatsoever, including those resulting from loss of use, data,
        or profits, whether or not foreseeable, and whether or not we have been advised of the possibility
        of such damages, or based on any theory of liability, including breach of contract or warranty,
        negligence or other tortious action, or any other claim arising out of or in connection with your
        use of or access to the Services.
      </p>
      <h4>12. Indemnity</h4>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates,
        and all of our respective officers, agents, partners, and employees, from and against any loss,
        liability, claim, demand, damages, costs, and expenses, including reasonable attorneys&apos; fees,
        arising out of or related to your use of the services, your violation of these terms, your
        violation of any rights of another, or your conduct in connection with the services. This
        indemnification obligation will survive the termination of these terms and your use of the services.
      </p>
      <h4>13. Governing Law</h4>
      <p>
        These Legal Terms shall be governed by and construed in accordance with the laws of Australia.
      </p>
      <h4>14. Dispute Resolution</h4>
      <p>
        Any dispute arising out of or in connection with these Legal Terms, including any question
        regarding its existence, validity, or termination, shall be referred to and finally resolved
        by arbitration under the rules of the Australian Centre for International Commercial Arbitration.
      </p>
      <h4>15. Language</h4>
      <p>
        These Legal Terms are only available in the English language.
      </p>
      <h4>16. Assignment</h4>
      <p>
        You may not assign, transfer, or delegate any of your rights and obligations under these Legal Terms
        without our prior written consent. We may assign, transfer, or delegate any or all of our rights
        and obligations under these Legal Terms without your consent.
      </p>
      <h4>17. Severability</h4>
      <p>
        If any provision of these Legal Terms is found to be unenforceable or invalid, that provision
        will be limited or eliminated to the minimum extent necessary so that these Legal Terms will
        otherwise remain in full force and effect and enforceable.
      </p>
      <h4>18. Third Party Rights</h4>
      <p>
        These Legal Terms are for the benefit of you and us, and are not intended to benefit any third party
        or be enforceable by any third party. The exercise of our and your rights in relation to these Legal
        Terms is not subject to the consent of any third party.
      </p>
      <h4>19. Entire Agreement</h4>
      <p>
        These Legal Terms constitute the entire agreement between you and us in relation to your use of the Services,
        and supersede all previous agreements in respect of your use of the Services.
      </p>
      <h4>20. Statutory and regulatory disclosures</h4>
      <p>
        We will not retain a copy of these Legal Terms specific to each user or customer.
        If we update these Legal Terms, the original version you agreed to will no longer be
        accessible on our website. We suggest that you save a copy of these Legal Terms for
        your future reference.
      </p>
      <h4>21. Contact Information</h4>
      <p>
        If you have any questions about these Terms, please <Link to="/contact-us">contact us</Link>.
      </p>
    </div>
  );
}

export default TermsOfUse;

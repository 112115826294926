import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { MdContentCopy } from 'react-icons/md';

import './ContactUs.css';

function ContactUs() {
  const [infoVisible, setInfoVisible] = useState(false);

  return (
    <div className="contact-us">
      <h4>Main Contact Information</h4>
      <p>
        Contact us by email at:{' '}
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = 'mailto:inquiries@phrase-forge.com';
            e.preventDefault();
          }}
        >
          inquiries@phrase-forge.com
        </Link>{' '}
        (
        <Link
          onClick={() => {
            navigator.clipboard.writeText('inquiries@phrase-forge.com');
          }}
          to="#"
        >
          <MdContentCopy /> Copy
        </Link>
        )
      </p>
      <h4>Data removal</h4>
      <div className="removal-main">
        <p>
          Send an email to{' '}
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = 'mailto:inquiries@phrase-forge.com';
              e.preventDefault();
            }}
          >
            inquiries@phrase-forge.com
          </Link>{' '}
          (
          <Link
            onClick={() => {
              navigator.clipboard.writeText('inquiries@phrase-forge.com');
            }}
            to="#"
          >
            <MdContentCopy /> Copy
          </Link>
          ) to request that we remove data linked to your identity. Include in
          your email:
        </p>
        <ul>
          <li>
            whether you used a social provider (Google/Apple) or a
            username/password combination to log in, and if you used a social
            provider, which provider you used.
          </li>
          <li>
            the email address associated with social provider you used, or your
            username if you did not use a social provider.
          </li>
        </ul>
        <p>
          If you want to remove data from multiple accounts, indicate the above
          information for each account separately.
        </p>
      </div>
      <div className="removal-details">
        <p>
          Read our <Link to="/privacy-policy">Privacy Policy</Link> for more
          information on data we collect. As we collect only data that is
          necessary to authenticate you and provide our services, removing your
          data will require us to disable your account.
        </p>
        <p>
          Data linked to your account will be removed as soon as possible after
          we receive a request (usually within 24 hours). We may contact you if
          we need more information in order to process the data removal.
        </p>
        <p>
          Please note that backups of most data (excluding authentication data
          such as email addresses) are kept for 90 days to enable us to restore
          information that is incorrectly altered or deleted. It is not possible
          to remove data from these backups, which are strictly accessed only in
          emergency situations.
        </p>
      </div>
      <h4>Other Contact Information</h4>
      <Link
        className={infoVisible ? 'hidden' : 'visible'}
        onClick={() => setInfoVisible(true)}
        to={'#'}
      >
        Display contact information
      </Link>
      <div className={infoVisible ? 'visible' : 'hidden'}>
        <h5>Business Address</h5>
        <p>PhraseForge Languages Pty Ltd</p>
        <p>30 Canterbury Road</p>
        <p>Suite 131</p>
        <p>Heathmont VIC 3135 Australia</p>
        <h5>Phone</h5>
        <p>+61 438 247 086</p>
      </div>
    </div>
  );
}

export default ContactUs;

import React from 'react';

import { Link } from 'react-router-dom';

import genderIntroImage from './gender-intro.jpg';
import hereIsMyCoffeeImage from './here-is-my-coffee.jpg';
import introduceMojImage from './introduce-moj.jpg';
import introduceMojaImage from './introduce-moja.jpg';
import introduceMojeImage from './introduce-moje.jpg';
import myBrotherImage from './my-brother.jpg';
import myFatherImage from './my-father.jpg';
import myMotherImage from './my-mother.jpg';
import mySisterImage from './my-sister.jpg';
import neuterNounsImage from './neuter-nouns.jpg';
import newNounsImage from './new-nouns.jpg';
import thatIsNotMyRadioImage from './that-is-not-my-radio.jpg';
import thatIsMyTableImage from './that-is-my-table.jpg';

import './LessonDesignExampleGender.css';

function LessonDesignExampleGender() {
  return (
    <div className="lesson-design">
      <h4>Lesson Design Example: Noun Gender in Slovak</h4>
      <p>
        In our article on <Link to={'/lesson-design'}>lesson design</Link>, we
        outline our approach to lesson design using a combination of techniques
        such as <strong>chunking</strong>, <strong>scaffolding</strong>,{' '}
        <strong>interleaving</strong> and <strong>active learning</strong>. Here
        we provide an example of how we apply these techniques to introduce the
        concept of noun gender in Slovak to learners. (For the sake of clarity,
        only a representative subset of the exercises are presented.)
      </p>
      <h5>Preparing for a New Feature</h5>
      <div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              In this example, we introduce learners to the concept of{' '}
              <em>noun&nbsp;gender</em> and its effect on the Slovak determiner{' '}
              <strong>môj</strong> (meaning <em>my</em>).
            </p>
            <p>
              Since learners may have no prior knowledge of noun gender, we
              begin with an information screen that introduces the concept. This
              provides a basic understanding and prepares them for the exercises
              that follow.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="Introduction to gender"
              className="image gender-intro-image"
              src={genderIntroImage}
            />
          </div>
        </div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              Next, we present Slovak words with different{' '}
              <em>natural&nbsp;genders</em>—specifically, words for family
              members. These are chosen so learners can start using noun gender
              without simultaneously learning genders of words, as these can be
              easily inferred from their meanings. By requiring the learner to
              repeat the words shown to them, these exercises introduce the
              vocabulary in a straightforward yet <strong>active</strong>{' '}
              manner.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="New nouns"
              className="image new-nouns-image"
              src={newNounsImage}
            />
          </div>
        </div>
      </div>
      <h5>
        First chunk - Use of the Word <strong>&quot;môj&quot;</strong>
      </h5>
      <div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              The word <strong>môj</strong> itself is now introduced. Initially,
              it is presented by analogy to the English <em>my</em>, and only
              the masculine form is shown to avoid overwhelming learners with
              too much information at once.
            </p>
            <p>
              To prevent misconceptions, we mention that there are different
              forms of the word for different genders, but this information is
              clearly set aside in a box, indicating that learners do not need
              to focus on it yet.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="Introduce môj"
              className="image introduce-moj-image"
              src={introduceMojImage}
            />
          </div>
        </div>
        <p>
          Next, we present the learner with a series of exercises requiring
          learners to use the feature.
        </p>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              In one exercise, learners select the word <strong>môj</strong>{' '}
              along with <strong>brat</strong> (<em>brother</em>) from a list to
              form <strong>môj&nbsp;brat</strong> (<em>my&nbsp;brother</em>).
            </p>
            <p>
              Since <strong>môj</strong> was just introduced, it is easy for
              learners to choose it from the list. The word{' '}
              <strong>brat</strong> is also easy to guess, as the other words
              all look similar to <strong>môj</strong>.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="My brother exercise"
              className="image my-brother-image"
              src={myBrotherImage}
            />
          </div>
        </div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              Now, having been introduced to <strong>môj</strong>, learners are
              required to enter it from memory into a text field alongside{' '}
              <strong>otec</strong> (<em>father</em>), to form the phrase{' '}
              <strong>môj&nbsp;otec</strong> (<em>my father</em>).
            </p>
            <p>
              As the word <strong>otec</strong> is already provided, learners
              only need to remember the spelling of <strong>môj</strong> from
              the previous exercise.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="My father exercise"
              className="image my-father-image"
              src={myFatherImage}
            />
          </div>
        </div>
      </div>
      <h5>
        Second chunk - use of <strong>&quot;môj&quot;</strong> and
        <strong>&quot;moja&quot;</strong> for different genders
      </h5>
      <div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              Having familiarized themselves with the use of{' '}
              <strong>môj</strong>, learners are introduced how it changes when
              used with different genders. An information screen explains the
              feminine form, <strong>moja</strong>.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="Introduce moja"
              className="image introduce-moja-image"
              src={introduceMojaImage}
            />
          </div>
        </div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              As before, learners select <strong>moja</strong> from a list and
              join it with <strong>sestra</strong> (<em>sister</em>) to form{' '}
              <strong>moja&nbsp;sestra</strong> (<em>my&nbsp;sister</em>).
            </p>
            <p>
              This time, it is slightly more challenging to choose the correct
              word, as they have seen both <strong>môj</strong> and{' '}
              <strong>moja</strong> and must select the appropriate one.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="My sister exercise"
              className="image my-sister-image"
              src={mySisterImage}
            />
          </div>
        </div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              Similarly to before, learners then enter <strong>moja</strong>{' '}
              into a text box alongside <strong>matka</strong> to form{' '}
              <strong>moja&nbsp;matka</strong> (<em>my&nbsp;mother</em>).
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="My mother exercise"
              className="image my-mother-image"
              src={myMotherImage}
            />
          </div>
        </div>
      </div>
      <h5>
        Third chunk - use of <strong>&quot;moje&quot;</strong> and identifying
        neuter nouns
      </h5>
      <div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              Having seen the word <strong>môj</strong> used with different
              genders, learners are ready to identify noun genders more
              generally, beyond words with natural genders.
            </p>
            <p>
              We introduce neuter nouns, explaining that they usually end with
              the letters <strong>o</strong>&nbsp;or&nbsp;<strong>e</strong>. A
              couple of familiar examples are shown.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="Neuter nouns"
              className="image neuter-nouns-image"
              src={neuterNounsImage}
            />
          </div>
        </div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              Learners can&apos;t do anything with this information yet, so we
              introduce the neuter form <strong>moje</strong> immediately and,
              as before, have the learner complete simple exercises to practice
              using the feature.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="Introduce moje"
              className="image introduce-moje-image"
              src={introduceMojeImage}
            />
          </div>
        </div>
        <p>
          We then continue by showing learners how to identify feminine and
          masculine nouns by their endings, and giving them some simple
          exercises to practice selecting the correct form of{' '}
          <strong>môj</strong>.
        </p>
      </div>
      <h5>Interleaving</h5>
      <div>
        <p>
          So far, learners have been introduced to the feature in a simple and
          controlled way, focusing on one aspect at a time, according to the{' '}
          <strong>chunking</strong> technique. Toward the end of the lesson, we
          switch to <strong>interleaving</strong>, switching between different
          aspects of the features and requiring learners to use them in various
          contexts.
        </p>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              In one exercise, learners construct the sentence{' '}
              <strong>&quot;Tu je moja káva&quot;</strong> (
              <em>Here is my coffee</em>). They are already familiar with the
              construction <strong>&quot;Tu je _&quot;</strong>, so the main
              challenge is selecting <strong>moja</strong> from a list of words,
              including the word <strong>môj</strong> (which is incorrect here).
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="Here is my coffee exercise"
              className="image here-is-my-coffee-image"
              src={hereIsMyCoffeeImage}
            />
          </div>
        </div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              We follow this with a similar exercise, but with the sentence{' '}
              <strong>&quot;To&nbsp;je&nbsp;môj&nbsp;stôl&quot;</strong>(
              <em>That&nbsp;is&nbsp;my&nbsp;table</em>). The challenge here is
              selecting <strong>môj</strong> to match the masculine noun{' '}
              <strong>stôl</strong>, without prior hints from the instructions.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="That is my table exercise"
              className="image that-is-my-table-image"
              src={thatIsMyTableImage}
            />
          </div>
        </div>
      </div>
      <h5>Moving towards independence using scaffolding</h5>
      <div>
        <div className="side-by-side">
          <div className="side-by-side-text">
            <p>
              In subsequent review exercises, learners construct sentences with{' '}
              <strong>môj</strong>, <strong>moja</strong> and{' '}
              <strong>moje</strong> in various contexts, with a decreasing level
              of guidance.
            </p>
            <p>
              For example, an exercise at the end of the review lesson requires
              learners to write a sentence from scratch, without guidance or
              given words to use, though they may click on the English words for
              hints to their translation.
            </p>
          </div>
          <div className="side-by-side-image">
            <img
              alt="That is not my radio exercise"
              className="image that-is-not-my-radio-image"
              src={thatIsNotMyRadioImage}
            />
          </div>
        </div>
        <p>
          The educational technique of <strong>scaffolding</strong> takes
          learners that start with no knowledge, introduces them to simpler,
          more guided exercises, and gradually increases the difficulty of the
          exercises, until eventually learners are able to construct whole
          sentences independently.
        </p>
        <p>
          By starting with simpler exercises with more guidance and a close
          focus on the feature, learners are able to build up their confidence
          and ability to use the feature.
        </p>
        <p>
          By eventually challenging learners to construct sentences
          independently, we ensure that they have a deep understanding of the
          feature and can use it confidently in complete sentences and a wide
          range of contexts.
        </p>
      </div>
    </div>
  );
}

export default LessonDesignExampleGender;

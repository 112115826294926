import React from 'react';

import { Link } from 'react-router-dom';

import './MasteryRatings.css';

function MasteryRatings() {
  return (
    <div className="updates">
      <h3>Mastery Ratings</h3>
      <p>
        Have you ever wondered how much you&apos;ve truly improved with your
        language learning? Our <strong>Mastery Rating</strong> system evaluates
        your proficiency in your target language by considering exercise
        difficulty, accuracy rate, and the use of hints and other assistance.
        It&apos;s designed to keep you motivated to continue learning and
        practicing.
      </p>
      <p>
        To view your Mastery Ratings, you&apos;ll need a{' '}
        <Link to="/plans-and-pricing">Standard Subscription</Link>, but
        regardless of your subscription level, they are calculated for you
        behind the scenes in order to help us provide you with a personalized
        learning experience.
      </p>
      <h4>How they work</h4>
      <p>
        You receive a Mastery Rating for each topic in your course, which
        measures your proficiency in that area. Ratings range from 1 (a complete
        beginner) to 10 (highly proficient).
      </p>
      <p>
        Each time you complete a lesson, your mastery rating for the topic is
        updated based on your performance. If you did better than expected, your
        rating will increase. However, your rating will never decrease if you
        you perform below expectations, in order to encourage regular practice.
      </p>
      <p>Your performance is assessed based on the following factors:</p>
      <ul>
        <li>The difficulty of the exercises</li>
        <li>
          The number of correct answers you achieve—excluding repeated exercises
          at the end of the lesson
        </li>
        <li>
          How many hints you use—completing exercises without assistance yields
          the best rating gains!
        </li>
        <li>
          Whether you used slowed speech for listening exercises—however,
          replaying the same clip won&apos;t affect your rating.
        </li>
        <li>
          Whether you had to correct minor mistakes on the way to a correct
          answer (this has only a small impact)
        </li>
        <li>
          The level of preparation you had for the exercise—completing an
          exercise without recent exposure to related material will result in a
          higher rating since it shows you aren&apos;t relying on your
          short-term memory. For example, revision exercises for a topic will
          give you a higher rating than introduction exercises. General Revision
          lessons will provide an even higher rating gain since they incorporate
          exercises from various topics.
        </li>
      </ul>
      <h4>Design—balancing accuracy and motivation</h4>
      <p>
        When designing the Mastery Rating system, we aimed to ensure that you
        remain motivated to keep learning and practicing. At the same time, we
        wanted to provide a clear an accurate understanding of your progress, as
        learners are more motivated when they can see their improvement.
      </p>
      <p>
        To achieve this, we decided that your mastery rating would{' '}
        <em>never</em> decrease, even if you perform below expectations. This
        way, you can continue practicing without fear of your rating dropping.
      </p>
      <p>
        We set the mastery rating maximum at a challenging yet attainable level,
        allowing for occasional mistakes and achievable within a reasonable
        timeframe (typically 3-4 lessons from scratch) once the learner is
        proficient in the topic.
      </p>
      <p>
        Achieving a 10/10 rating for a topic should feel like an accomplishment,
        but it shouldn&apos;t feel like a grind or require perfect scores.
        Generally, we calibrate our revision exercises so that learners
        correctly complete around 70-80% of them—a higher percentage often
        indicates that the learner&apos;s progress may not be as efficient as it
        could be.
      </p>
      <p>
        We also want to ensure that the Mastery Rating you achieve feels
        meaningful. If you could easily obtain a perfect score by completing
        numerous easy exercises, it wouldn&apos;t accurately reflect your
        proficiency, and you might lose motivation to pursue a high rating.
      </p>
      <p>
        Therefore, we designed the Mastery Rating system to be strongly
        influenced by exercise difficulty and the use of hints. This ensures
        that a learner who achieves a high rating for a topic has actually
        demonstrated independent proficiency.
      </p>
      <h4>How we use your Mastery Ratings</h4>
      <p>
        We use your Mastery Ratings to determine the Recommended Lessons and the
        exercises you encounter in Revision lessons, including General Revision.
        This applies regardless of whether you can view your Mastery Rating.
      </p>
      <p>
        Mastery Ratings influence our recommendations for new lessons. When your
        Mastery Ratings are lower, you will encounter more Revision lessons and
        fewer new topics. This approach ensures you build a solid foundation in
        the language before advancing to new material. The topics for the
        recommended revision lessons are based on your Mastery Ratings and the
        time elapsed since you last completed a lesson in that topic.
      </p>
      <p>
        Mastery Ratings also impact the exercises you encounter in Revision
        lessons. With lower Mastery Ratings, you&apos;ll see mainly exercises
        with a lower difficulty level, such as filling in blanks or arranging
        words. Conversely, higher Mastery Ratings will present you with more
        challenging exercises, like writing full sentences or tackling
        comprehension tasks. This ensures you are consistently challenged and
        making progress in your language learning journey.
      </p>
      <h4>Improving your Mastery Rating</h4>
      <p>
        The Mastery Rating is designed so that improving your rating aligns with
        long-term improvement. To enhance your Mastery Rating and, consequently,
        your long-term proficiency, consider the following tips:
      </p>
      <ul>
        <li>
          Take your time. Review your answers to ensure accuracy before
          submitting.
        </li>
        <li>
          Use hints sparingly. Hints are there to assist you, but over-reliance
          can slow your learning. We recommend using hints only when you&apos;re
          truly stuck, rather than just checking the answer.
        </li>
        <li>
          Use slowed speech only when necessary. You&apos;ll learn the language
          more quickly by primarily listening to speech at a natural speed.
        </li>
        <li>
          Use Select Mode (available with a{' '}
          <Link to="/plans-and-pricing">Standard Subscription</Link>) to
          practice revision exercises. Completing a revision exercise every 24
          hours will help you retain knowledge and improve your Mastery Rating.
        </li>
        <li>
          However, avoid doing too many revision exercises from the same topic
          in one day, as this can lead to dependence on short-term memory, and
          your results won&apos;t reflect your long-term ability. For this
          reason, we reduce the Mastery Rating gain when you frequently repeat
          revision exercises from the same topic.
        </li>
        <li>
          Use General Revision exercises (available with a{' '}
          <Link to="/plans-and-pricing">Standard Subscription</Link>) to
          practice exercises from diverse topics. General Revision exercises
          offer the fastest rating gains, as they mix exercises from different
          topics, which is more challenging than topic-specific exercises. Note
          that you must complete a certain number of exercises from each topic
          in your General Revision before your rating increases.
        </li>
      </ul>
    </div>
  );
}

export default MasteryRatings;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet } from 'react-router-dom';

import Nav from '../../components/nav/Nav';

import banner from './banner_resized.png';
import './Root.css';

interface IRootRoute {
  outlet?: React.ReactNode;
}

function Root(props: IRootRoute) {
  return (
    <div>
      <Helmet>
        <title>Phraseforge Languages</title>
        <meta
          name="description"
          content="PhraseForge Languages develops language learning apps, currently focusing on the Slovak language."
        />
      </Helmet>
      <div className="page">
        <header className="banner">
          <Link to="/">
            <img src={banner} className="banner-graphic" alt="logo" />
          </Link>
          <Nav />
        </header>
        <main>{props.outlet ? props.outlet : <Outlet />}</main>
        <footer>
          <div>© 2024 PhraseForge Languages Pty Ltd. All rights reserved</div>
          <div>
            <Link className="footer-nav" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div>
            <Link className="footer-nav" to="/terms-of-use">
              Terms of Use
            </Link>
          </div>
          <div>
            <Link className="footer-nav" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Root;

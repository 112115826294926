import React from 'react';

import { Link } from 'react-router-dom';

import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h3>Privacy Policy for PhraseForge Languages Pty Ltd</h3>
      <strong>Effective Date:</strong> 13 September 2024
      <h4>1. Introduction</h4>
      <p>
        This is the privacy policy for <strong>PhraseForge Languages Pty Ltd</strong>{" "}
        (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;){" "}
        including the <strong>PhraseForge Slovak</strong> App (&quot;the App&quot;). 
        Our ACN (Australian Company Number) is 680 626 608. We are committed to
        protecting your privacy and ensuring a safe and secure user experience.
        This Privacy Policy outlines how we collect, use, and protect your
        personal information when you use our App.
      </p>
      <h4>2. Information We Collect or Use</h4>
      <h5>2.1 Authentication Providers</h5>
      <p>
        We use third-party authentication providers to securely authenticate your access
        to the App. These providers may collect and store your personal information.
        We do not provide these providers with any personal information other than
        what is necessary to authenticate your access to the App.
      </p>
      <h6>2.1.1 Amazon Cognito</h6>
      <p>
        We use Amazon Cognito as our authentication provider.
        Amazon Cognito is a subset of Amazon Web Services (AWS). AWS&apos;s privacy policy can be found{' '}
        <Link to="https://aws.amazon.com/privacy/">here</Link>. Amazon Cognito is the only
        authentication provider used when signing in with a username and password.
      </p>
      <h6>2.1.2 Sign in with Apple</h6>
      <p>
        Our services include the option to sign in with Apple via Amazon Cognito.
        Apple&apos;s privacy policy can be found{' '}
        <Link to="https://www.apple.com/legal/privacy/en-ww/">here</Link>.
      </p>
      <h6>2.1.3 Sign in with Google</h6>
      <p>
        Our services include the option to sign in
        with Google via Amazon Cognito. Google&apos;s privacy policy can be found{' '}
        <Link to="https://policies.google.com/privacy">here</Link>.
      </p>
      <h5>2.2 Authentication Data Collected</h5>
      <h6>2.2.1 Email Address</h6>
      <p>
        When we use Amazon Cognito to authenticate your access to the App,
        we collect your email address. If you use Amazon Cognito to sign in with a
        username and password, the email address you enter and use to verify your account
        will be the one that is stored. If you use a social provider (Google/Apple) to sign in,
        the email address associated with the account used to sign in will be stored.
      </p>
      <h6>2.2.2 Generated Identifier</h6>
      <p>
        We use Amazon Cognito to provide a unique identifier linked to your email
        address for secure authentication. If you use Amazon Cognito to sign in with
        a username and password, the username will be your identifier. If you use a social
        provider (Google/Apple) to sign in, a unique identifier provided by
        Amazon Cognito will be used.
      </p>
      <h6>2.2.3 Other personal information offered by Sign in with Google</h6>
      <p>
        As indicated by the prompt shown when using Sign in with Google, Sign in with Google
        offers to share additional personal information with us,
        such as your name, language preference, and profile picture.
        While we are entitled to access this information through Sign in with Google,
        we do not access, use, store, or distribute such data in any way.
      </p>
      <h5>2.3 User Progress and Settings</h5>
      <p>
        We record your progress and settings in the App against the generated
        identifier to provide a personalized learning experience. All information in this section
        is stored against the generated identifier (see 2.2.2 above) unless otherwise specified.
      </p>
      <h6>2.3.1 Progress and Results</h6>
      <p>
        We store your progress in the App, such as completed lessons and exercises, in order to
        provide a personalized learning experience.
      </p>
      <p>
        In particular, at the end of each lesson (except General Revision), we calculate a new rating
        based on your performance and store it against the generated identifier. We also
        store the level at which each topic has been completed. The results of individual exercises,
        along with the answers selected or entered by the user, are not stored in a form linked to your identifier,
        but may be stored in an irreversibly anonymised form.
      </p>
      <p>
        For General Revision, we temporarily store information about how the user has performed in
        each exercise until a large enough sample size has been completed to update the user&apos;s rating;
        the answers selected or entered by the user are not stored. This data is deleted once it has
        been used to update the rating.
      </p>
      <p>
        For Vocab Drills, we store each drill item separately and update it based on the
        whether it has been answered correctly (or reported as correct/incorrect); the
        answer selected or entered by the user is not stored.
      </p>
      <p>
        We may store additional information about exercise completion in a fully and irreversibly
        anonymized form for the purpose of data analysis. This information cannot be linked to
        your identifier.
      </p>
      <h6>2.3.2 Lesson Completion</h6>
      <p>
        We store information about when lessons have been completed in order to
        provide personalized recommended lessons. In particular, the day of the user&apos;s last
        lesson completion for each topic is stored.
      </p>
      <p>
        Separately, we store information about when lessons have been completed in order to
        keep track of our Streaks feature. In particular, the day of the user&apos;s last
        lesson completion is stored, along with the number
        of days in a row that the user has completed a lesson, and the longest such streak.
      </p>
      <p>
        We also update the user&apos;s eligibity to complete a lesson in the free mode based on
        the completion time of each lesson.
      </p>
      <h6>2.3.3 Settings</h6>
      <p>
        We store the user&apos;s settings in the App, such as keyboard use preference,
        streak enablement, and noun gender color preferences, along with any other settings
        found in the App Settings screen, with the exception of Daily Reminders, which are
        only stored on the user&apos;s device.
      </p>
      <h6>2.3.4 Introduction Sequence</h6>
      <p>
        We store the progress of users through the introduction sequence (prior to submission of the first
        lesson) is stored in a fully and irreversibly anonymized form for the purpose of analysing
        user acceptance of this sequence. This information cannot be linked to your identity.
      </p>
      <h5>2.4 Payment and Purchase Records</h5>
      <h6>2.4.1 Payment Information</h6>
      <p>
        We use the standard services offered by the Apple App Store on iOS, and the Google Play Store
        on Android, to handle payments for the App. We do not have access to any financial information
        such as credit card details or billing addresses.
      </p>
      <h6>2.4.1 Purchase History</h6>
      <p>
        We use RevenueCat, a third-party payment processor, to manage purchase history,
        stored against the generated identifier (2.2.2). This includes the date, time and country
        of each purchase, cancellation, transfer, modification, or other action related to purchases.
      </p>
      <p>
        RevenueCat also stores certain information about each user including country,
        first and most recent login times (users are automatically logged in to RevenueCat
        after logging in to the App), and most recent device locale.
      </p>
      <p>
        RevenueCat&apos;s privacy policy can be found <Link to="https://www.revenuecat.com/privacy">here</Link>.
      </p>
      <h5>2.5 User Feedback</h5>
      <p>
        When you provide feedback to us through the app, such as through the &quot;Send feedback&quot; button
        at the bottom of each exercise screen, we store the feedback you provide. This feedback undergoes a
        rigorous anonymization process, ensuring that all personally identifiable information is removed,
        making it irreversibly anonymized and unable to be linked back to your identifier.
      </p>
      <p>
      </p>
      <h5>2.6 Customer Service</h5>
      <p>
        When you contact us for customer service, we store the information you provide, such as your
        email address and the content of your message. You may request that this information be removed
        once the issue has been resolved as part of your message, or by contacting us at a later date.
      </p>
      <h5>2.7 Audio Recording</h5>
      <p>
        We may allow users to input answers to exercises by speaking, in order to enhance
        user experience by providing an alternative to typing and allow users to practice their pronunciation
        and speaking ability. To facilitate this, audio may be recorded and processed using OpenAI&apos;s
        Whisper model.
      </p>
      <p>
        Audio recording will only occur in response to clearly labeled user actions. This means that audio will
        only be captured when you explicitly choose to use the voice input feature. Audio input is never
        required to complete an exercise or use a feature of the App.
      </p>
      <p>
        Once recorded, your audio will be sent to OpenAI&apos;s Whisper model for processing. This processing is
        ephemeral, meaning that the audio data is used solely for the purpose of converting your speech to text
        and is not stored or retained by us or OpenAI after processing is complete.
      </p>
      <p>
        Additionally, the text output of the processing is not stored or retained by us or OpenAI after it has
        been used to provide you with a response to your input.
      </p>
      <p>
        We do not store any audio recordings or transcriptions after they have been processed. This ensures that
        your spoken input remains confidential and is not accessible after the initial processing.
      </p>
      <p>
        OpenAI&apos;s privacy policy can be found <Link to="https://openai.com/policies/privacy-policy/">here</Link>.
      </p>
      <h4>3. How we use your information</h4>
      <p>We use the collected information for the following purposes:</p>
      <h5>3.1 Authentication</h5>
      <p>
        We use your email address (2.2.1) and generated identifier (2.2.2) to securely
        authenticate your access to the App and prevent unauthorized access. We do not use
        email addresses obtained during authenitcation for any other purpose.
      </p>
      <h5>3.2 Personalized Experience</h5>
      <p>
        We use your progress and settings (2.3) to provide a personalized learning experience.
        For example, we use your progress to recommend lessons and exercises at appropriate times,
        and we use your settings to tailor the App to your preferences.
      </p>
      <h5>3.3 Payment Processing</h5>
      <p>
        We use RevenueCat to handle payment processing (2.4) and to provide us with information
        about purchases and user behavior.
      </p>
      <h5>3.4 Customer Service</h5>
      <p>
        We use the information you provide when you contact us for customer service (2.6) to
        respond to your inquiries and provide support.
      </p>
      <h5>3.5 Data Analysis</h5>
      <p>
        We may use anonymized data from lesson results, user progress, user feedback,
        app settings, and the introduction sequence (2.3.1, 2.3.2, 2.3.3, 2.3.4, 2.5) for data analysis
        to improve the App and our services.
      </p>
      <h5>3.6 Legal Compliance</h5>
      <p>
        We may use your information to comply with applicable laws, regulations, or legal
        processes, or to protect our rights or the rights of our users.
      </p>
      <h5>3.7 User Feedback</h5>
      <p>
        Anonymized feedback (2.5) is used to fix errors, improve our services, enhance user experience,
        and develop new features. We analyze this data to better understand user needs and preferences.
      </p>
      <h5>3.8 Audio Recording</h5>
      <p>
        Audio recordings (2.7) are used solely for the purpose of providing you with a convenient and efficient
        way to enter answers by speaking, and to allow for practice of pronunciation and speaking in general.
        We do not use the audio data for any other purposes, such as analytics or marketing.
      </p>
      <h4>4. Lawful Bases</h4>
      <h5>4.1 Contract</h5>
      <p>
        We process your information to fulfill our obligations under our Terms of Service
        when you use the App, such as providing the services that you have requested by
        using the App.
      </p>
      <h5>4.2 Legitimate Interest</h5>
      <p>
        We process your information for our legitimate interests, such as improving the App,
        and protecting the security of the App and our users. We protect your privacy by
        irreversibly anonymizing information used for data analysis, and by only using
        information linked to personal identifiers for the purposes outlined in this Privacy Policy,
        namely providing a personalized and secure learning experience, and enabling your
        access to the App and any purchases therein.
      </p>
      <h4>5. Data Sharing and Disclosure</h4>
      <p>
        Except as outlined above (2.1, 2.4), we do not share your personal
        information with third parties, except where required by law. Your data
        is never used for any purpose other than those outlined in this Privacy Policy.
      </p>
      <p>
        If PhraseForge Languages or substantially all of its assets are acquired by a third party, 
        personal data held by it about its customers will be one of the transferred assets, and this
        privacy policy will continue to apply to your data.
      </p>
      <h4>6. Data Retention and Removal</h4>
      <h5>6.1 Data Retention</h5>
      <p>
        User data, as outlined in 2.2 and 2.3, is stored in production systems
        for as long as the user has an account with the App.
      </p>
      <p>
        In addition, such user data is backed up for 90 days in a separate, secure system and is
        only accessed for the specific and sole purpose of amending or reversing
        software malfunctions, human error, or malicious input that would
        otherwise adversely affect the user experience. Email addresses (2.2.1)
        are not included in these backups.
      </p>
      <h5>6.2 Data Removal</h5>
      <p>
        Your linked data will be removed from regular program function on request,
        either by selecting the option inside the App 
        (under Settings &gt; Account Settings &gt; Delete account) or by{" "}
        <Link to="/contact-us">contacting us</Link>. 
        As all linked data is essential for providing our services, it is not possible
        to remove data without disabling your account.
      </p>
      <p>
        Data removal from our internal production systems will be removed immediately
        after using the &quot;Delete account&quot; option in the App.
        Authenication and payment data removal, along with production systems data removal if requested
        via a method outside the App, will be completed as soon as possible after we receive a
        request, usually within 24 hours. We may contact you if we need more
        information to process the data removal.
      </p>
      <p>
        For technical reasons we cannot remove data that is stored in backups (6.1).
        We are also unable to remove any data that has been irreversibly anonymized (2.3.1, 2.5).
      </p>
      <h4>7. Security</h4>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal information against unauthorized access,
        alteration, disclosure, or destruction, including through backups (6.1)
        and authentication procedures (2.1).
        We also use secure encryption to protect your data in transit.
      </p>
      <p>
        However, no method of transmission over the internet, or method of
        electronic storage, is 100% secure. While we strive to use commercially
        acceptable means to protect your personal information, we cannot guarantee
        its absolute security.
      </p>
      <h4>8. Child safety</h4>
      <p>
        The App is intended for use only by persons who are at least 18 years of age.
        By using the App, you confirm to us that you meet this requirement.
        If you are under the age of 18, you confirm you have received permission from
        your parent or guardian before using the App or sending us personal information.
      </p>
      <h4>9. International Transfers</h4>
      <p>
        The data that we collect from you will be stored securely
        on Amazon Web Services (&quot;AWS&quot;) in the USA.
        The European Commission has made an &quot;adequacy decision&quot; with respect to the data
        protection laws of the United States. Transfers to the United States will be protected
        by appropriate safeguards.
      </p>
      <h4>10. User Rights</h4>
      <p>
        You have the right, either as an EU citizen under the GDPR, or otherwise:
      </p>
      <ul>
        <li>to be informed about personal data processing that we may carry out</li>
        <li>to access data and information about how your personal data is being used</li>
        <li>to rectify inaccurate or incomplete personal data</li>
        <li>to object to our processing of your personal information</li>
        <li>to obtain your personal information for the purpose of transferring it to another party</li>
        <li>to request the deletion of your personal information</li>
        <li>to restrict the processing of your personal data under certain circumstances</li>
      </ul>
      <p>
        To exercise these rights, or if you have any questions or
        concerns about this Privacy Policy or our data practices, please contact
        us using the details on <Link to="/contact-us">our contact page</Link>.
      </p>
      <h4>11. Changes to This Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes through a notification within the App, as well as by
        posting the new Privacy Policy on this page and updating the
        &quot;Effective Date&quot; at the top.
      </p>
      <h4>12. Contact Us</h4>
      <p>
        Questions, comments and requests regarding this privacy policy can be made
        by contacting us using the details on <Link to="/contact-us">our contact page</Link>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;

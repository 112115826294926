import React from 'react';

import { Link } from 'react-router-dom';

import './LessonDesign.css';

function LessonDesign() {
  return (
    <div className="lesson-design">
      <h3>Lesson Design</h3>
      <p>
        Each of our lessons is designed to teach a select handful of language
        features, such as vocabulary and grammar. The goal is to take the
        learner from having no knowledge of the feature to being able to use
        them confidently and independently in complete sentences across a wide
        range of contexts.
      </p>
      <h4>Techniques</h4>
      <h5>Active learning</h5>
      <p>
        <strong>Active learning</strong> involves teaching by doing. It requires
        learners to actively engage with the material, rather than just
        passively reading or listening. Research has found that active learning
        is generally more effective than passive learning.
      </p>
      <p>
        We use active learning extensively throughout our lessons in the form of
        interactive exercises to reinforce the information the learner has just
        acquired and to practice using language features in various contexts.
      </p>
      <p>
        While active learning is emphasized, some passive learning is still
        beneficial as it primes the learner to be able to better understand the
        active learning exercises. We do this by periodically showing short
        information boxes that introduce the learner to the concept of the
        active learning exercises that they are about to do.
      </p>
      <p>
        This brief introduction ensures that learners can recognize the patterns
        in the active learning exercises more quickly and confidently than if
        they were left to figure them out for themselves.
      </p>
      <h5>Scaffolding</h5>
      <p>
        Using the <strong>scaffolding</strong> technique, we begin by presenting
        the learner with a simple, guided exercise that requires them to use the
        feature. For example, the user may be asked to fill in a missing word in
        a sentence from a pre-populated list.
      </p>
      <p>
        We then gradually increase the complexity of the exercises and reduce
        the level of assistance until the learner is able to use the feature
        confidently and independently. For example, the user may be asked to
        write a complete sentence using the feature without any assistance.
      </p>
      <p>
        This scaffolding technique is used over the course of several lessons,
        The initial lesson focusing mainly on the introduction and
        familiarization of the feature, followed by a subsequent review lesson
        focusing on consolidation, and then revision lessons focusing on
        practice, application, and extension.
      </p>
      <h5>Chunking</h5>
      <p>
        The <strong>chunking</strong> technique involves breaking down a feature
        into smaller, more manageable parts. We then present the learner with a
        series of exercises that address each chunk in turn.
      </p>
      <p>
        For example, rather than introducing all forms of a word at once, we may
        introduce them one by one, ensuring that the learner understands each
        form before moving on to the next.
      </p>
      <p>
        Chunking is used to introduce learners to a new feature, ensuring they
        understand it in a straightforward controlled and isolated manner before
        progressing to more complex exercises.
      </p>
      <h5>Interleaving</h5>
      <p>
        As the learner&apos;s familiarity with a particular feature increases,
        we transition from chunking to <strong>interleaving</strong>. This
        involves presenting the learner with a series of exercises that cover a
        range of topics, rather than focusing on a single topic in isolation.
      </p>
      <p>
        Interleaving helps learners to practice choosing the correct feature
        from a wider range of options, which is necessary for using language
        independently. This approach is more challenging than chunking and is
        used towards the end of a lesson, once the learner has a solid grasp of
        the features.
      </p>
      <h5>Features in Context</h5>
      <p>
        We typically introduce language features by providing examples of
        complete sentences that are familiar to learners, aside from the new
        feature. This approach helps learners understand the feature in context
        and see how it integrates into a full sentence. Additionally, it
        reinforces the learner&apos;s understanding of previously introduced
        features by presenting them in a new context.
      </p>
      <p>
        At the very beginning of the course, we introduce certain features in
        short phrases to ensure clarity for beginners. In contrast, later in the
        course, we may present new features within the broader context of an
        entire paragraph or conversation, as this wider context is important to
        distinguish nuances in meaning.
      </p>
      <h5>Examples</h5>
      <p>
        See how we apply these techniques to introduce the concept of noun
        gender in our{' '}
        <Link to={'/lesson-design-example-gender'}>lesson design example</Link>.
      </p>
    </div>
  );
}

export default LessonDesign;

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ContactUs from './routes/contact-us/ContactUs';
import CourseDesign from './routes/course-design/CourseDesign';
import ErrorPage from './error-page';
import Home from './routes/home/Home';
import LanguageCoverage from './routes/language-coverage/LanguageCoverage';
import LessonDesign from './routes/lesson-design/LessonDesign';
import LessonDesignExampleGender from './routes/lesson-design-example-gender/LessonDesignExampleGender';
import MasteryRatings from './routes/mastery-ratings/MasteryRatings';
import PlansAndPricing from './routes/plans-and-pricing/PlansAndPricing';
import PrivacyPolicy from './routes/privacy-policy/PrivacyPolicy';
import TermsOfUse from './routes/terms-of-use/TermsOfUse';
// import SlovakTips from './routes/slovak-tips/SlovakTips';
// import TipsFormality from './routes/tips-formality/TipsFormality';
// import TipsGreetings from './routes/tips-greetings/TipsGreetings';
// import TipsPronunciation from './routes/tips-pronunciation/TipsPronunciation';
// import TipsWordOrder from './routes/tips-word-order/TipsWordOrder';
import Root from './routes/root/Root';
import Updates from './routes/updates/Updates';

import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Root outlet={<ErrorPage />} />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            element: <Home />,
            errorElement: <ErrorPage />,
            index: true,
          },
          {
            element: <Home />,
            errorElement: <ErrorPage />,
            path: 'index',
          },
          {
            element: <ContactUs />,
            errorElement: <ErrorPage />,
            path: 'contact-us',
          },
          {
            element: <CourseDesign />,
            errorElement: <ErrorPage />,
            path: 'course-design',
          },
          {
            element: <LanguageCoverage />,
            errorElement: <ErrorPage />,
            path: 'language-coverage',
          },
          {
            element: <LessonDesign />,
            errorElement: <ErrorPage />,
            path: 'lesson-design',
          },
          {
            element: <LessonDesignExampleGender />,
            errorElement: <ErrorPage />,
            path: 'lesson-design-example-gender',
          },
          {
            element: <MasteryRatings />,
            errorElement: <ErrorPage />,
            path: 'mastery-ratings',
          },
          {
            element: <PlansAndPricing />,
            errorElement: <ErrorPage />,
            path: 'plans-and-pricing',
          },
          {
            element: <PrivacyPolicy />,
            errorElement: <ErrorPage />,
            path: 'privacy-policy',
          },
          // {
          //   element: <SlovakTips />,
          //   errorElement: <ErrorPage />,
          //   path: 'slovak-tips',
          // },
          {
            element: <TermsOfUse />,
            errorElement: <ErrorPage />,
            path: 'terms-of-use',
          },
          // {
          //   element: <TipsFormality />,
          //   errorElement: <ErrorPage />,
          //   path: 'tips-formality',
          // },
          // {
          //   element: <TipsGreetings />,
          //   errorElement: <ErrorPage />,
          //   path: 'tips-greetings',
          // },
          // {
          //   element: <TipsPronunciation />,
          //   errorElement: <ErrorPage />,
          //   path: 'tips-pronunciation',
          // },
          // {
          //   element: <TipsWordOrder />,
          //   errorElement: <ErrorPage />,
          //   path: 'tips-word-order',
          // },
          {
            element: <Updates />,
            errorElement: <ErrorPage />,
            path: 'updates',
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

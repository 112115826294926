const Prices: Record<string, string> = {
  'afg~apple': '$1.99 USD',
  'alb~apple': '$1.99 USD',
  'alb~google': '$2.39 USD',
  'dza~apple': '$1.99 USD',
  'dza~google': 'DZD 275.00',
  'ago~apple': '$1.99 USD',
  'ago~google': '$1.99 USD',
  'aia~apple': '$1.99 USD',
  'atg~apple': '$1.99 USD',
  'arg~apple': '$1.99 USD',
  'arg~google': '$1.99 USD',
  'arm~apple': '$1.99 USD',
  'arm~google': '$1.99 USD',
  'abw~google': '$1.99 USD',
  'aus~apple': '$2.99 AUD',
  'aus~google': '$2.99 AUD',
  'aut~apple': '€1.99 EUR',
  'aut~google': '€2.19 EUR',
  'aze~apple': '$1.99 USD',
  'aze~google': '$1.99 USD',
  'bhs~apple': '$1.99 USD',
  'bhs~google': '$1.99 USD',
  'bhr~apple': '$1.99 USD',
  'bhr~google': '$1.99 USD',
  'bgd~google': 'BDT 290.00',
  'brb~apple': '$1.99 USD',
  'blr~apple': '$1.99 USD',
  'blr~google': '$2.39 USD',
  'bel~apple': '€1.99 EUR',
  'bel~google': '€2.19 EUR',
  'blz~apple': '$1.99 USD',
  'blz~google': '$1.99 USD',
  'ben~apple': '$1.99 USD',
  'ben~google': '€1.84 EUR',
  'bmu~apple': '$1.99 USD',
  'bmu~google': '$1.99 USD',
  'btn~apple': '$1.99 USD',
  'bol~apple': '$1.99 USD',
  'bol~google': 'BOB 13.99',
  'bih~apple': '€1.99 EUR',
  'bih~google': '$1.99 USD',
  'bwa~apple': '$1.99 USD',
  'bwa~google': '$1.99 USD',
  'bra~apple': 'R$9.90 BRL',
  'bra~google': 'R$10.99 BRL',
  'bva~apple': '$1.99 USD',
  'bva~google': '$1.99 USD',
  'brn~apple': '$1.99 USD',
  'bgr~apple': 'BGN 3.99',
  'bgr~google': 'BGN 4.29',
  'bfa~apple': '$1.99 USD',
  'bfa~google': '€1.84 EUR',
  'khm~apple': '$1.99 USD',
  'khm~google': '$1.99 USD',
  'cmr~apple': '$1.99 USD',
  'cmr~google': '$2.37 USD',
  'can~apple': '$2.99 CAD',
  'can~google': '$2.79 CAD',
  'cpv~apple': '$1.99 USD',
  'cpv~google': '$1.99 USD',
  'cym~apple': '$1.99 USD',
  'cym~google': '$1.99 USD',
  'caf~google': '€1.84 EUR',
  'tcd~apple': '$1.99 USD',
  'tcd~google': '$1.99 USD',
  'chl~apple': '$1990 CLP',
  'chl~google': '$2200 CLP',
  'chn~apple': '¥15.00 CNY',
  'col~apple': '$9,900 COP',
  'col~google': '$8,500 COP',
  'com~google': '$1.99 USD',
  'cod~apple': '$1.99 USD',
  'cod~google': '$1.99 USD',
  'cog~apple': '$1.99 USD',
  'cog~google': '$1.99 USD',
  'cri~apple': '$1.99 USD',
  'cri~google': 'CRC 1000.00',
  'civ~apple': '$1.99 USD',
  'civ~google': '$1.99 USD',
  'hrv~apple': '€1.99 EUR',
  'hrv~google': '€2.29 EUR',
  'cyp~apple': '€1.99 EUR',
  'cyp~google': '€2.19 EUR',
  'cze~apple': 'Kč 49.00 CZK',
  'cze~google': 'Kč 54.99 CZK',
  'dnk~apple': 'kr 19.00 DKK',
  'dnk~google': 'kr 17.00 DKK',
  'dji~google': '$1.99 USD',
  'dma~apple': '$1.99 USD',
  'dma~google': '$1.99 USD',
  'dom~apple': '$1.99 USD',
  'dom~google': '$1.99 USD',
  'ecu~apple': '$1.99 USD',
  'ecu~google': '$1.99 USD',
  'egy~apple': 'E£99.99 EGP',
  'egy~google': 'E£109.99 EGP',
  'slv~apple': '$1.99 USD',
  'slv~google': '$1.99 USD',
  'eri~google': '$1.99 USD',
  'est~apple': '€1.99 EUR',
  'est~google': '€2.19 EUR',
  'swz~apple': '$1.99 USD',
  'fji~apple': '$1.99 USD',
  'fji~google': '$1.99 USD',
  'fin~apple': '€1.99 EUR',
  'fin~google': '€2.29 EUR',
  'fra~apple': '€1.99 EUR',
  'fra~google': '€2.19 EUR',
  'gab~apple': '$1.99 USD',
  'gab~google': '€1.84 EUR',
  'gmb~apple': '$1.99 USD',
  'gmb~google': '$1.99 USD',
  'geo~apple': '$1.99 USD',
  'geo~google': 'GEL 6.00',
  'deu~apple': '€1.99 EUR',
  'deu~google': '€2.19 EUR',
  'gha~apple': '$1.99 USD',
  'gha~google': 'GHS 40.00',
  'gib~google': '£1.49 GBP',
  'grc~apple': '€1.99 EUR',
  'grc~google': '€2.29 EUR',
  'grd~apple': '$1.99 USD',
  'grd~google': '$1.99 USD',
  'gua~apple': '$1.99 USD',
  'gua~google': '$1.99 USD',
  'gui~google': '$1.99 USD',
  'gnb~apple': '$1.99 USD',
  'gnb~google': '€1.84 EUR',
  'guy~apple': '$1.99 USD',
  'hti~google': '$1.99 USD',
  'hnd~apple': '$1.99 USD',
  'hnd~google': '$1.99 USD',
  'hkg~apple': '$18.00 HKD',
  'hkg~google': '$15.90 HKD',
  'hun~apple': 'Ft 999 HUF',
  'hun~google': 'Ft 899 HUF',
  'isl~apple': '$1.90 USD',
  'isl~google': '€2.28 EUR',
  'ind~apple': '₹199.00 INR',
  'ind~google': '₹200.00 INR',
  'idn~apple': 'Rp 29,000 IDR',
  'idn~google': 'Rp 31,000 IDR',
  'irq~apple': '$1.99 USD',
  'irq~google': 'IQD 2,600',
  'ire~apple': '€1.99 EUR',
  'ire~google': '€2.29 EUR',
  'isr~apple': '₪29.90 ILS',
  'isr~google': '₪7.50 ILS',
  'ita~apple': '€1.99 EUR',
  'ita~google': '€2.19 EUR',
  'jam~apple': '$1.99 USD',
  'jam~google': '$1.99 USD',
  'jpn~apple': '¥300 JPY',
  'jpn~google': '¥300 JPY',
  'jor~apple': '$1.99 USD',
  'jor~google': 'JOD 1.400',
  'kaz~apple': '₸990 KZT',
  'kaz~google': '₸1,090 KZT',
  'ken~apple': '$1.99 USD',
  'ken~google': 'KES 300.00',
  'kor~apple': '₩3,300 KRW',
  'kor~google': '₩3,000 KRW',
  'kos~apple': '€1.99 EUR',
  'kwt~apple': '$1.99 USD',
  'kwt~google': '$1.99 USD',
  'kgz~apple': '$1.99 USD',
  'kgz~google': '$1.99 USD',
  'lao~apple': '$1.99 USD',
  'lao~google': '$1.99 USD',
  'lie~google': 'CHF 1.90',
  'lva~apple': '€1.99 EUR',
  'lva~google': '€2.19 EUR',
  'lbn~apple': '$1.99 USD',
  'lbn~google': '$1.99 USD',
  'lbr~apple': '$1.99 USD',
  'lbr~google': '$1.99 USD',
  'lby~apple': '$1.99 USD',
  'lby~google': '$1.99 USD',
  'ltu~apple': '€1.99 EUR',
  'ltu~google': '€2.19 EUR',
  'lux~apple': '€1.99 EUR',
  'lux~google': '€2.19 EUR',
  'mac~apple': '$1.99 USD',
  'mac~google': 'MOP 15.88',
  'mdg~apple': '$1.99 USD',
  'mwi~apple': '$1.99 USD',
  'mys~apple': 'RM 9.90 MYR',
  'mys~google': 'RM 9.49 MYR',
  'mdv~apple': '$1.99 USD',
  'mdv~google': '$1.99 USD',
  'mli~apple': '$1.99 USD',
  'mli~google': '€1.84 EUR',
  'mlt~apple': '€1.99 EUR',
  'mlt~google': '€2.17 EUR',
  'mrt~apple': '$1.99 USD',
  'mus~apple': '$1.99 USD',
  'mus~google': '$1.99 USD',
  'mex~apple': '$39.00 MXN',
  'mex~google': '$46.00 MXN',
  'fsm~apple': '$1.99 USD',
  'fsm~google': '$1.99 USD',
  'mda~apple': '$1.99 USD',
  'mda~google': '$2.39 USD',
  'mco~google': '€2.19 EUR',
  'mng~apple': '$1.99 USD',
  'mne~apple': '€1.99 EUR',
  'msr~apple': '$1.99 USD',
  'mar~apple': '$1.99 USD',
  'mar~google': 'MAD 23.99',
  'moz~apple': '$1.99 USD',
  'moz~google': '$1.99 USD',
  'mrb~apple': '$1.99 USD',
  'mrb~google': 'MMK 4,000',
  'nam~apple': '$1.99 USD',
  'nam~google': '$1.99 USD',
  'nru~apple': '$1.99 USD',
  'npl~apple': '$1.99 USD',
  'npl~google': '$2.25 USD',
  'nld~apple': '€1.99 EUR',
  'nld~google': '€2.19 EUR',
  'nzl~apple': '$3.99 NZD',
  'nzl~google': '$3.79 NZD',
  'nic~apple': '$1.99 USD',
  'nic~google': '$1.99 USD',
  'ner~apple': '$1.99 USD',
  'ner~google': '€1.84 EUR',
  'nga~apple': '₦3,200 NGN',
  'nga~google': '₦3,520 NGN',
  'mkd~apple': '$1.99 USD',
  'mkd~google': '$1.99 USD',
  'nor~apple': 'kr 29.00 NOK',
  'nor~google': 'kr 27.00 NOK',
  'omn~apple': '$1.99 USD',
  'omn~google': '$1.99 USD',
  'pak~apple': 'Rs 500 PKR',
  'pak~google': 'Rs 550 PKR',
  'plw~apple': '$1.99 USD',
  'pan~apple': '$1.99 USD',
  'pan~google': '$1.99 USD',
  'png~apple': '$1.99 USD',
  'png~google': '$1.99 USD',
  'pry~apple': '$1.99 USD',
  'pry~google': 'PYG 20,000',
  'per~apple': 'PEN 7.90',
  'per~google': 'PEN 7.49',
  'phl~apple': '₱99.00 PHP',
  'phl~google': '₱115.00 PHP',
  'pol~apple': 'zł 9.99 PLN',
  'pol~google': 'zł 9.79 PLN',
  'prt~apple': '€1.99 EUR',
  'prt~google': '€2.29 EUR',
  'qat~apple': 'QAR 7.99',
  'qat~google': 'QAR 7.50',
  'rou~apple': 'lei 9.99 RON',
  'rou~google': 'lei 10.99 RON',
  'rus~apple': '₽199.00 RUB',
  'rus~google': '₽189.00 RUB',
  'rwa~apple': '$1.99 USD',
  'rwa~google': '$1.99 USD',
  'wsm~google': '$1.99 USD',
  'smr~google': '€1.79 EUR',
  'stp~apple': '$1.99 USD',
  'sau~apple': 'SAR 9.99',
  'sau~google': 'SAR 8.59',
  'sen~apple': '$1.99 USD',
  'sen~google': '$1.99 USD',
  'srb~apple': '€1.99 EUR',
  'srb~google': 'RSD 249',
  'syc~apple': '$1.99 USD',
  'syc~google': '$1.99 USD',
  'sle~apple': '$1.99 USD',
  'sle~google': '$1.99 USD',
  'sgp~apple': '$2.98 SGD',
  'sgp~google': '$2.98 SGD',
  'svk~apple': '€1.99 EUR',
  'svk~google': '€2.19 EUR',
  'svn~apple': '€1.99 EUR',
  'svn~google': '€2.19 EUR',
  'slb~apple': '$1.99 USD',
  'slb~google': '$1.99 USD',
  'som~google': '$1.99 USD',
  'zaf~apple': 'R 39.99 ZAR',
  'zaf~google': 'R 39.99 ZAR',
  'esp~apple': '€1.99 EUR',
  'esp~google': '€2.19 EUR',
  'lka~apple': '$1.99 USD',
  'lka~google': 'LKR 575',
  'kna~apple': '$1.99 USD',
  'kna~google': '$1.99 USD',
  'lca~apple': '$1.99 USD',
  'lca~google': '$1.99 USD',
  'vct~apple': '$1.99 USD',
  'sur~apple': '$1.99 USD',
  'sur~google': '$1.99 USD',
  'swe~apple': 'kr 29.00 SEK',
  'swe~google': 'kr 26.00 SEK',
  'che~apple': 'CHF 2.00',
  'che~google': 'CHF 1.70',
  'twn~apple': '$60.00 TWD',
  'twn~google': '$67.00 TWD',
  'tjk~apple': '$1.99 USD',
  'tjk~google': '$1.99 USD',
  'tza~apple': 'TZS 5,900',
  'tza~google': 'TZS 5,500',
  'tha~apple': '฿79.00 THB',
  'tha~google': '฿72.00 THB',
  'tgo~google': '€1.84 EUR',
  'ton~apple': '$1.99 USD',
  'ton~google': '$1.99 USD',
  'tto~apple': '$1.99 USD',
  'tto~google': '$1.99 USD',
  'tun~apple': '$1.99 USD',
  'tun~google': '$1.99 USD',
  'tur~apple': '₺79.99 TRY',
  'tur~google': '₺81.99 TRY',
  'tkm~apple': '$1.99 USD',
  'tkm~google': '$1.99 USD',
  'tca~apple': '$1.99 USD',
  'tca~google': '$1.99 USD',
  'uga~apple': '$1.99 USD',
  'uga~google': '$2.35 USD',
  'ukr~apple': '$1.99 USD',
  'ukr~google': '₴99.99 UAH',
  'uae~apple': 'AED 7.99',
  'uae~google': 'AED 7.69',
  'gbr~apple': '£1.99 GBP',
  'gbr~google': '£1.79 GBP',
  'usa~apple': '$1.99 USD',
  'usa~google': '$1.99 USD',
  'ury~apple': '$1.99 USD',
  'ury~google': '$1.99 USD',
  'uzb~apple': '$1.99 USD',
  'uzb~google': '$2.23 USD',
  'vat~google': '€1.79 EUR',
  'vut~apple': '$1.99 USD',
  'vut~google': '$1.99 USD',
  'ven~apple': '$1.99 USD',
  'ven~google': '$1.99 USD',
  'vnm~apple': '₫49,000 VND',
  'vnm~google': '₫51,000 VND',
  'yem~apple': '$1.99 USD',
  'yem~google': '$1.99 USD',
  'zmb~apple': '$1.99 USD',
  'zmb~google': '$1.99 USD',
  'zwe~apple': '$1.99 USD',
  'zwe~google': '$1.99 USD',
};

export const getPrice = (region: string, provider: string): string | null => {
  const key = `${region}~${provider}` as keyof typeof Prices;
  return Prices[key] || null;
};

export const locationOptions = [
  { value: 'afg', label: 'Afghanistan' },
  { value: 'alb', label: 'Albania' },
  { value: 'dza', label: 'Algeria' },
  { value: 'ago', label: 'Angola' },
  { value: 'aia', label: 'Anguilla' },
  { value: 'atg', label: 'Antigua and Barbuda' },
  { value: 'arg', label: 'Argentina' },
  { value: 'arm', label: 'Armenia' },
  { value: 'abw', label: 'Aruba' },
  { value: 'aus', label: 'Australia' },
  { value: 'aut', label: 'Austria' },
  { value: 'aze', label: 'Azerbaijan' },
  { value: 'bhs', label: 'Bahamas' },
  { value: 'bhr', label: 'Bahrain' },
  { value: 'bgd', label: 'Bangladesh' },
  { value: 'brb', label: 'Barbados' },
  { value: 'blr', label: 'Belarus' },
  { value: 'bel', label: 'Belgium' },
  { value: 'blz', label: 'Belize' },
  { value: 'ben', label: 'Benin' },
  { value: 'bmu', label: 'Bermuda' },
  { value: 'btn', label: 'Bhutan' },
  { value: 'bol', label: 'Bolivia' },
  { value: 'bih', label: 'Bosnia and Herzegovina' },
  { value: 'bwa', label: 'Botswana' },
  { value: 'bra', label: 'Brazil' },
  { value: 'bva', label: 'British Virgin Islands' },
  { value: 'brn', label: 'Brunei' },
  { value: 'bgr', label: 'Bulgaria' },
  { value: 'bfa', label: 'Burkina Faso' },
  { value: 'khm', label: 'Cambodia' },
  { value: 'cmr', label: 'Cameroon' },
  { value: 'can', label: 'Canada' },
  { value: 'cpv', label: 'Cabo Verde' },
  { value: 'cym', label: 'Cayman Islands' },
  { value: 'caf', label: 'Central African Republic' },
  { value: 'tcd', label: 'Chad' },
  { value: 'chl', label: 'Chile' },
  { value: 'chn', label: 'China' },
  { value: 'col', label: 'Colombia' },
  { value: 'com', label: 'Comoros' },
  { value: 'cod', label: 'Congo, Democratic Republic of the' },
  { value: 'cog', label: 'Congo, Republic of the' },
  { value: 'cri', label: 'Costa Rica' },
  { value: 'civ', label: "Côte d'Ivoire" },
  { value: 'hrv', label: 'Croatia' },
  { value: 'cyp', label: 'Cyprus' },
  { value: 'cze', label: 'Czechia' },
  { value: 'dnk', label: 'Denmark' },
  { value: 'dji', label: 'Djibouti' },
  { value: 'dma', label: 'Dominica' },
  { value: 'dom', label: 'Dominican Republic' },
  { value: 'ecu', label: 'Ecuador' },
  { value: 'egy', label: 'Egypt' },
  { value: 'slv', label: 'El Salvador' },
  { value: 'eri', label: 'Eritrea' },
  { value: 'est', label: 'Estonia' },
  { value: 'swz', label: 'Eswatini' },
  { value: 'fji', label: 'Fiji' },
  { value: 'fin', label: 'Finland' },
  { value: 'fra', label: 'France' },
  { value: 'gab', label: 'Gabon' },
  { value: 'gmb', label: 'Gambia' },
  { value: 'geo', label: 'Georgia' },
  { value: 'deu', label: 'Germany' },
  { value: 'gha', label: 'Ghana' },
  { value: 'gib', label: 'Gibraltar' },
  { value: 'grc', label: 'Greece' },
  { value: 'grd', label: 'Grenada' },
  { value: 'gua', label: 'Guatemala' },
  { value: 'gui', label: 'Guinea' },
  { value: 'gnb', label: 'Guinea-Bissau' },
  { value: 'guy', label: 'Guyana' },
  { value: 'hti', label: 'Haiti' },
  { value: 'hnd', label: 'Honduras' },
  { value: 'hkg', label: 'Hong Kong' },
  { value: 'hun', label: 'Hungary' },
  { value: 'isl', label: 'Iceland' },
  { value: 'ind', label: 'India' },
  { value: 'idn', label: 'Indonesia' },
  { value: 'irq', label: 'Iraq' },
  { value: 'ire', label: 'Ireland' },
  { value: 'isr', label: 'Israel' },
  { value: 'ita', label: 'Italy' },
  { value: 'jam', label: 'Jamaica' },
  { value: 'jpn', label: 'Japan' },
  { value: 'jor', label: 'Jordan' },
  { value: 'kaz', label: 'Kazakhstan' },
  { value: 'ken', label: 'Kenya' },
  { value: 'kor', label: 'Korea, Republic of' },
  { value: 'kos', label: 'Kosovo' },
  { value: 'kwt', label: 'Kuwait' },
  { value: 'kgz', label: 'Kyrgyzstan' },
  { value: 'lao', label: 'Laos' },
  { value: 'lva', label: 'Latvia' },
  { value: 'lbn', label: 'Lebanon' },
  { value: 'lbr', label: 'Liberia' },
  { value: 'lby', label: 'Libya' },
  { value: 'lie', label: 'Liechtenstein' },
  { value: 'ltu', label: 'Lithuania' },
  { value: 'lux', label: 'Luxembourg' },
  { value: 'mac', label: 'Macao' },
  { value: 'mdg', label: 'Madagascar' },
  { value: 'mwi', label: 'Malawi' },
  { value: 'mys', label: 'Malaysia' },
  { value: 'mdv', label: 'Maldives' },
  { value: 'mli', label: 'Mali' },
  { value: 'mlt', label: 'Malta' },
  { value: 'mrt', label: 'Mauritania' },
  { value: 'mus', label: 'Mauritius' },
  { value: 'mex', label: 'Mexico' },
  { value: 'fsm', label: 'Micronesia' },
  { value: 'mda', label: 'Moldova' },
  { value: 'mco', label: 'Monaco' },
  { value: 'mng', label: 'Mongolia' },
  { value: 'mne', label: 'Montenegro' },
  { value: 'msr', label: 'Montserrat' },
  { value: 'mar', label: 'Morocco' },
  { value: 'moz', label: 'Mozambique' },
  { value: 'mrb', label: 'Myanmar' },
  { value: 'nam', label: 'Namibia' },
  { value: 'nru', label: 'Nauru' },
  { value: 'npl', label: 'Nepal' },
  { value: 'nld', label: 'Netherlands' },
  { value: 'nzl', label: 'New Zealand' },
  { value: 'nic', label: 'Nicaragua' },
  { value: 'ner', label: 'Niger' },
  { value: 'nga', label: 'Nigeria' },
  { value: 'mkd', label: 'North Macedonia' },
  { value: 'nor', label: 'Norway' },
  { value: 'omn', label: 'Oman' },
  { value: 'pak', label: 'Pakistan' },
  { value: 'plw', label: 'Palau' },
  { value: 'pan', label: 'Panama' },
  { value: 'png', label: 'Papua New Guinea' },
  { value: 'pry', label: 'Paraguay' },
  { value: 'per', label: 'Peru' },
  { value: 'phl', label: 'Philippines' },
  { value: 'pol', label: 'Poland' },
  { value: 'prt', label: 'Portugal' },
  { value: 'qat', label: 'Qatar' },
  { value: 'rou', label: 'Romania' },
  { value: 'rus', label: 'Russia' },
  { value: 'rwa', label: 'Rwanda' },
  { value: 'wsm', label: 'Samoa' },
  { value: 'smr', label: 'San Marino' },
  { value: 'stp', label: 'São Tomé and Príncipe' },
  { value: 'sau', label: 'Saudi Arabia' },
  { value: 'sen', label: 'Senegal' },
  { value: 'srb', label: 'Serbia' },
  { value: 'syc', label: 'Seychelles' },
  { value: 'sle', label: 'Sierra Leone' },
  { value: 'sgp', label: 'Singapore' },
  { value: 'svk', label: 'Slovakia' },
  { value: 'svn', label: 'Slovenia' },
  { value: 'slb', label: 'Solomon Islands' },
  { value: 'som', label: 'Somalia' },
  { value: 'zaf', label: 'South Africa' },
  { value: 'esp', label: 'Spain' },
  { value: 'lka', label: 'Sri Lanka' },
  { value: 'kna', label: 'St Kitts and Nevis' },
  { value: 'lca', label: 'St Lucia' },
  { value: 'vct', label: 'St Vincent and the Grenadines' },
  { value: 'sur', label: 'Suriname' },
  { value: 'swe', label: 'Sweden' },
  { value: 'che', label: 'Switzerland' },
  { value: 'twn', label: 'Taiwan' },
  { value: 'tjk', label: 'Tajikistan' },
  { value: 'tza', label: 'Tanzania' },
  { value: 'tha', label: 'Thailand' },
  { value: 'tgo', label: 'Togo' },
  { value: 'ton', label: 'Tonga' },
  { value: 'tto', label: 'Trinidad and Tobago' },
  { value: 'tun', label: 'Tunisia' },
  { value: 'tur', label: 'Türkiye' },
  { value: 'tkm', label: 'Turkmenistan' },
  { value: 'tca', label: 'Turks and Caicos Islands' },
  { value: 'uga', label: 'Uganda' },
  { value: 'ukr', label: 'Ukraine' },
  { value: 'uae', label: 'United Arab Emirates' },
  { value: 'gbr', label: 'United Kingdom' },
  { value: 'usa', label: 'United States' },
  { value: 'ury', label: 'Uruguay' },
  { value: 'uzb', label: 'Uzbekistan' },
  { value: 'vat', label: 'Vatican City' },
  { value: 'vut', label: 'Vanuatu' },
  { value: 'ven', label: 'Venezuela' },
  { value: 'vnm', label: 'Vietnam' },
  { value: 'yem', label: 'Yemen' },
  { value: 'zmb', label: 'Zambia' },
  { value: 'zwe', label: 'Zimbabwe' },
];

export const appStoreOptions = [
  { value: 'apple', label: 'Apple App Store' },
  { value: 'google', label: 'Google Play Store' },
];

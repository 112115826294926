import React from 'react';

import selectModeImage from './select-mode.png';

import './CourseDesign.css';

// TODO: Add example screenshots, and a couple of specific examples looked at
// more closely.

function CourseDesign() {
  return (
    <div className="course-design">
      <h3>Course Design</h3>
      <div className="course-design-intro">
        <div className="course-design-intro-text">
          <p>
            Learning Slovak, with its intricate grammar and unfamiliar
            vocabulary, can be quite challenging. Our Slovak course is crafted
            to help you begin mastering Slovak in a supportive and efficient
            way.
          </p>
          <p>
            The design of our Slovak course is grounded in research and
            experience in language learning. While there are numerous methods to
            learn a language, our goal is to provide a structured and engaging
            mobile learning experience that assists learners in progressing at
            their own pace.
          </p>
          <p>
            You can use our course on its own or in combination with other
            resources.
          </p>
        </div>
        <img
          src={selectModeImage}
          alt="Course design intro image"
          className="course-design-intro-image"
        />
      </div>
      <h4>Course Structure</h4>
      <p>
        Determining the sequence in which to present language features and
        topics can be challenging. We consider the following factors when
        organizing the content of our Slovak course:
      </p>
      <h5>Scaffolding</h5>
      <p>
        We introduce language features in a manner that builds on learners&apos;
        existing knowledge, adding new elements incrementally.
      </p>
      <p>
        Foundational language features are introduced early, while more
        specialized or situational ones are presented later.
      </p>
      <p>
        We continue to use these features throughout the course so learners can
        see how they apply in different contexts.
      </p>
      <h5>Frequency of use</h5>
      <p>
        When planning the course structure, we consider how often language
        features are used in everyday communication. Generally, frequently used
        words are introduced earlier. However, we also consider how easily these
        features can be integrated into learners&apos; existing knowledge.
      </p>
      <p>
        For instance, while there are many words for different types of food,
        most words on their own aren&apos;t used all that frequently. So, we
        select a few to introduce early to serve as examples, allowing learners
        to explore the others later either in the course or on their own.
      </p>
      <h5>Simplicity</h5>
      <p>
        At the beginning of the course, we focus on language features that are
        easy to learn and use. For example, we start with sentences whose
        grammar closely matches English and introduce more complex or unfamiliar
        grammar later. We also prioritize short words that are easier to
        pronounce, write, and remember.
      </p>
      <p>
        However, this isn&apos;t a strict rule. Some difficult-to-pronounce
        words are introduced early because they are important for understanding
        and use in context.
      </p>
      <p>
        Similarly, certain grammar features, like verbal aspect, reflexive
        verbs, and clitics, are introduced early because they are fundamental to
        many other Slovak language features. Delaying these features too long
        would greatly restrict the range of language that learners can
        understand and use.
      </p>
    </div>
  );
}

export default CourseDesign;
